import React, { useState } from "react";
import cn from "classnames";
import styles from "./Trade.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../../components/Icon";
import { getDigitsAfterDecimal, getPostiveNegativeNum } from "../../../../components/helper";
import TradeSkeleton from "../../../../components/Skeleton/TradeSkeleton";

const Trade = ({
  loading,
  isFavorite,
  search,
  setSearch,
  activeColumn,
  toggleFavorite,
  activeTab,
  handleSort,
  filteredData,
  sortMarketNav,
  handleTabClick
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = parseInt(process.env.REACT_APP_MARKET_PAGE_PAGINATION_COUNT);

  const filteredMarkets = filteredData?.filter(i =>
    i.name.toLowerCase().includes(search.toLowerCase()) ||
    i.slug.toLowerCase().includes(search.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredMarkets?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredMarkets?.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleImageError = (e) => {
    e.target.src = "/images/content/dummyCoinIcon.png";
  };

  return (
    <div className={styles.trade}>
      <form className={styles.form}>
        <input
          className={styles.input}
          type="text"
          onChange={(e) => setSearch(e.target.value)}
          name="search"
          placeholder="Search coin"
          required
        />
        <button onClick={(e) => { e.preventDefault() }} className={styles.result}>
          <Icon name="search" size="20" />
        </button>
      </form>
      <div className={styles.foot}>
        <div className={styles.nav}>
          {sortMarketNav.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: activeTab === x,
              })}
              onClick={() => {
                handleTabClick(x);
                setCurrentPage(1);
              }}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>#</div>
          <div className={styles.col}>
            <div className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "name" })}
              onClick={() => { handleSort("name", filteredData) }}>
              Name
            </div>
          </div>
          <div className={styles.col}>
            <div className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "price" })}
              onClick={() => { handleSort("price", filteredData) }}>
              Price
            </div>
          </div>
          <div className={styles.col}>
            <div className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "dayChange" })}
              onClick={() => { handleSort("dayChange", filteredData) }}>
              24h %
            </div>
          </div>
          <div className={styles.col}>
            <div
              className={cn("sorting", { [styles.activeColumn]: activeColumn.key === "weekChange" })}
              onClick={() => { handleSort("weekChange", filteredData) }}
            >
              7d %
            </div>
          </div>
          <div className={styles.col}>
            Marketcap
            <Icon name="coin" size="20" />
          </div>
          <div className={styles.col}>
            Volume (24h) <Icon name="chart" size="20" />
          </div>
          <div className={styles.col}>Action</div>
        </div>
        {loading ? <TradeSkeleton rowCount={30} colCount={8} />
          :
          currentItems?.map((i, ind) => {
            return (search !== "" &&
              i.name.toLowerCase().indexOf(search.toLowerCase()) ===
              -1 && i.slug.toLowerCase().indexOf(search.toLowerCase()) ===
              -1) ? null : (
              <div className={styles.row} key={ind}>
                <div className={styles.col}>
                  <div className={styles.line}>
                    <button
                      className={cn(styles.favorite, {
                        [styles.active]: currentItems?.includes(i?.id),
                      })}
                      onClick={() => toggleFavorite(i, ind)}
                    >
                      {isFavorite(i.id) ? (
                        <Icon name="star" size="16" fill="#22767d" />
                      ) : (
                        <Icon name="star-outline" size="16" />
                      )}
                    </button>
                    {(currentPage - 1) * itemsPerPage + ind + 1}
                  </div>
                </div>
                <div className={styles.col}>
                  <div className={styles.item}>
                    <div className={styles.icon}>
                      <img src={i.icon} onError={handleImageError} alt="Coin" />
                    </div>
                    <div className={styles.details}>
                      <span className={styles.subtitle}>{i.name}</span>
                      <span className={styles.currency}>{i.symbol}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.col}>
                  <div className={styles.label}>Price</div>
                  {getDigitsAfterDecimal(i.currentMarketPrice, i.priceDecimalPrecision)}
                </div>
                <div className={styles.col}>
                  {getPostiveNegativeNum(i.dayChange) ?
                    <div className={styles.positive}>{getDigitsAfterDecimal(i.dayChange, 2)}%</div>
                    :
                    <div className={styles.negative}>{getDigitsAfterDecimal(i.dayChange, 2)}%</div>
                  }
                </div>
                <div className={styles.col}>
                  {getPostiveNegativeNum(i.weekChange) ?
                    <div className={styles.positive}>{getDigitsAfterDecimal(i.weekChange, 2)}%</div>
                    :
                    <div className={styles.negative}>{getDigitsAfterDecimal(i.weekChange, 2)}%</div>
                  }
                </div>
                <div className={styles.col}>
                  <div className={styles.label}>Marketcap</div>
                  {i.cap}
                </div>
                <div className={styles.col}>
                  <div className={styles.label}>Volume (24h)</div>
                  {i.volume}
                </div>
                <div className={styles.col}>
                  <Link className={cn("button-small", styles.button)} to={`/exchange/${i.slug}`}>
                    Trade
                  </Link>
                </div>
              </div>
            )
          })}
      </div>
      <div className={styles.pagination}>
        <button
          className={cn(styles.paginationButton, { [styles.disabled]: currentPage === 1 })}
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {[...Array(totalPages).keys()].map(pageNumber => (
          <button
            key={pageNumber}
            className={cn(styles.paginationButton, { [styles.active]: currentPage === pageNumber + 1 })}
            onClick={() => paginate(pageNumber + 1)}
          >
            {pageNumber + 1}
          </button>
        ))}
        <button
          className={cn(styles.paginationButton, { [styles.disabled]: currentPage === totalPages })}
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};
export default Trade;