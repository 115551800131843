import { Navigate, Outlet, Redirect, Route } from "react-router";
import requestHandler from "../actions/httpClient";
import { addNotification } from "./Notification";

const getPostiveNegativeNum = (value) => {
    if (value >= 0) {
        return true;
    } else {
        return false;
    };
};

const getDigitsAfterDecimal = (price, digits, fee) => {
    if(fee){
        if(digits < 5){
            return parseFloat(Math.trunc(price * Math.pow(10, digits + 3)) / Math.pow(10, digits + 3)).toFixed(digits + 3);
        }
        else{
            return parseFloat(Math.trunc(price * Math.pow(10, digits)) / Math.pow(10, digits)).toFixed(digits);
        }
    }
    else{
        return parseFloat(Math.trunc(price * Math.pow(10, digits)) / Math.pow(10, digits)).toFixed(digits);
    }
};


const twoDigitsAfterDecimal = (price, coin) => {
    switch (coin?.toLowerCase()) {
        case 'eur':
        case 'usdt':
            return parseFloat(Math.trunc(price * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2);
        case 'btc':
        case 'eth':
        case 'ltc':
            return Math.trunc(price * Math.pow(10, 8)) / Math.pow(10, 8);
        case 'trx':
        case 'xrp':
            return Math.trunc(price * Math.pow(10, 5)) / Math.pow(10, 5);
        default:
            return Math.trunc(price * Math.pow(10, 4)) / Math.pow(10, 4);
    }
}

const toLocaleStringDateFormat = (value) => {
    return new Date(value).toLocaleString();
};

const ProtectedRoutes = () => {
    const isAuthenticated = localStorage.getItem("signature");
  
    return isAuthenticated ? <Outlet /> : <Navigate to={process.env.REACT_APP_GLOBAL_URL} replace />;
  };


const resendOtp = async (email, type) => {

    try {
        const data = {
            'email': email,
            'type': type,
        }



        const payload = await requestHandler('gen_otp', 'post', data, 'jwt_token', "file")
        if (payload.status === 201) {
            addNotification({
                title: 'Success',
                message: "OTP resend successfully",
                type: 'success'
            });

        }

        return payload;
    } catch (error) {


        return error

    }


}

const firstLetterCapitalHandler = (str) =>{
    return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
};

const formatFeeHandler = (amount, decimalPrecision) => {
    let feeCal = amount * (process.env.REACT_APP_MAKER_FEE / 100);
    let formattedFee = getDigitsAfterDecimal(feeCal, 8);
    formattedFee = formattedFee
      .toString()
      .replace(/(\.\d*?)0+$/,"$1")
      .replace(/\.$/, "");
    return formattedFee;
}

export {
    getPostiveNegativeNum,
    getDigitsAfterDecimal,
    twoDigitsAfterDecimal,
    toLocaleStringDateFormat,
    ProtectedRoutes,
    resendOtp,
    firstLetterCapitalHandler,
    formatFeeHandler
};


























  // const getExchangeData = async (slug) => {
  //   const formData = {
  //     market_slug: slug,
  //     signature: localStorage.getItem("signature")
  //   }

  //   try {
  //     const payload = await requestHandler('getOpenOrders', 'post', formData, 'jwt_token')

  //     { ordeerActionResponse.length==0 && setBuy(payload.data.data.buy) }
  //     { ordeerActionResponse.length==0 && setSell(payload.data.data.sell) }
      
  //     setMarketTrades(payload?.data?.data?.market_trades)
  //     setOpenOrder(payload?.data?.data?.open_order)
  //     setCurrentMarketDetails(payload?.data?.data?.current_market_details)
  //     setExchangeDta(payload)
  //   } catch (error) {
  //   }
  // }

  // const exchangeUpdateSell = (data) => {
  //   setSell(data)
  // }

  // const exchangeUpdateBuy = (data) => {
  //   setBuy(data)
  // }