import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./EnterAmount.module.sass";
import Icon from "../../../components/Icon";
import { addNotification } from "../../../components/Notification";
import { getDigitsAfterDecimal } from "../../../components/helper";
import SimpleReactValidator from "simple-react-validator";

const EnterAmount = ({ goNext, goBack, value, coinBalance, decimalPrecision, priceDecimalPrecision }) => {
  const [price, setPrice] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState();

  return (
    <form className={styles.item} action="" onSubmit={(e) => { e.preventDefault() }}>
      <div className={styles.control}>
        <button className={styles.back} onClick={goBack}>
          <Icon name="arrow-prev" size="14" />
          Enter amount
        </button>
        <div className={styles.money}>
          Selling {value?.name}
          <img src={value?.icon} alt="Coin" />
        </div>
      </div>
      <div className={styles.payment}>
        <div className={styles.field}>
          <div className={styles.value}>{price}</div>
          <input
            className={styles.input}
            value={price}
            onChange={(e) => {
              const regex = /^\d*\.?\d*$/
              if (e.target.value === '' || regex.test(e.target.value)) {
                setPrice(e.target.value);
              }
            }}
            placeholder="0"
            name="price"
          />
        </div>
        <div className={cn("h4", styles.sign)}>{value?.symbol}</div>
      </div>
      <div className={styles.approx}>{(price && price > 0) ? `${price} ${value?.symbol}` : `0 ${value?.symbol}`} ≈ {(price && price > 0) ? `${getDigitsAfterDecimal((price * value?.currentMarketPrice), priceDecimalPrecision)} USDT` : `0 USDT`}</div>
      <div className={styles.price}>
        You have <span>{getDigitsAfterDecimal(coinBalance, decimalPrecision)}</span> {value?.symbol}
      </div>
      <span className={styles.danger}>{simpleValidator.current.message("price", price, "required")}</span>
      <div className={styles.btns}>
        {/* <button className={cn("button", styles.button)} onClick={goNext}>
          Sell
        </button> */}
        <button className={cn("button", styles.button)} onClick={(e) => {
          e.preventDefault();
          if (simpleValidator.current.allValid()) {
            if (parseFloat(price) > 0) {
              if ((parseFloat(price) * parseFloat(value.currentMarketPrice)) >= parseFloat(process.env.REACT_APP_MINIMUM_ORDER_AMOUNT)) {
                if (parseFloat(price) > parseFloat(coinBalance)) {
                  addNotification({
                    title: 'Error',
                    message: 'Insufficient balance',
                    type: 'danger'
                  })
                }
                else {
                  goNext(price)
                }
              }
              else {
                addNotification({
                  title: 'Alert',
                  message: `Amount must be greater than or equal to ${process.env.REACT_APP_MINIMUM_ORDER_AMOUNT} USDT.`,
                  type: 'danger'
                })
              }
            }
            else {
              addNotification({
                title: 'Error',
                message: "You can't add negative values and 0 in price field.",
                type: 'danger'
              })
            }
          }
          else {
            simpleValidator.current.showMessages();
            setForceUpdate(forceUpdate + 1);
          }
        }}>
          Sell
        </button>
      </div>
    </form>
  );
};

export default EnterAmount;
