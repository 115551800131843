import { BrowserRouter as Router, Switch, Route, Routes } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import Market from "./screens/Market";
import LearnCrypto from "./screens/LearnCrypto";
import LearnCryptoDetails from "./screens/LearnCryptoDetails";
import Exchange from "./screens/Exchange";
import WalletOverview from "./screens/WalletOverview";
import WalletOverviewDetails from "./screens/WalletOverviewDetails";
import BuyCrypto from "./screens/BuyCrypto";
import SellCrypto from "./screens/SellCrypto";
import ProfileInfo from "./screens/ProfileInfo";
import AmlPolicy from "./screens/AmlPolicy";
import ChangePassword from "./screens/ChangePassword";
import TermsAndConditions from "./screens/TermsAndConditions";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import LocationRestriction from "./screens/LocationRestriction";
import TermsAndConditionsForReferralProgram from "./screens/TermsAndConditionsForReferralProgram";
import BuyAndSellBitcoin from "./screens/BuyAndSellBitcoin";
import BuyAndSellTron from "./screens/BuyAndSellTron";
import BuyAndSellEthereum from "./screens/BuyAndSellEthereum";
import BuyAndSellLitecoin from "./screens/BuyAndSellLitecoin";
import BuyAndSellRipple from "./screens/BuyAndSellRipple";
import BuyAndSellSteller from "./screens/BuyAndSellStellar";
import Fees from "./screens/Fees";
import LegalCenter from "./screens/LegalCenter";
import HelpCenter from "./screens/HelpCenter";
import KycPolicy from "./screens/KycPolicy";
import OrdersHistory from "./screens/OrdersHistory";
import OpenOrdersList from "./screens/OpenOrderList";
import UserKycVerification from "./screens/UserKycVerifications";
import FiatDepositSuccess from "./screens/FiatDepositSuccess";
import FiatDepositFailed from "./screens/FiatDepositFailed";
import FiatDepositCancel from "./screens/FiatDepositCancel";
import DepositList from "./screens/DepositList";
import WithdrawList from "./screens/WithdrawList";
import Videos from "./screens/Videos"
import BitdenexPay from './screens/BitdenexPay'
import BitdenexPartners from './screens/BitdenexPartners'
import BitdenexApp from "./screens/BitdenexApp";
import CoinListing from './screens/CoinListing'
import PageNotFound from './screens/PageNotFound'
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import requestHandler from "./actions/httpClient";
import RegionTransferList from './screens/RegionTransferList'
import BitdenexPayTransactionList from './screens/BitdenexPayTransactionList'
import ReferralProgram from './screens/ReferralProgram'
import MyReferrals from './screens/MyReferrals'
import CouponAndRewards from './screens/CouponAndRewards'
import CashbackAndRewardList from './screens/CashbackAndRewardList';
import BankVerification from "./screens/BankVerification";
import DepositFiat from "./screens/DepositFiat";
import FiatDepositList from "./screens/FiatDepositList";
import BitdenexToken from './screens/BitdenexToken'
import ProtectedRoutes from "./components/ProtectedRoutes";
// import { socket } from "./socket";
import WalletTransferList from './screens/WalletTransferList';
import Staking from './screens/Staking';
import BuyAndSellBitdenex from "./screens/BuyAndSellBitdenex";
import BuyAndSellCardano from "./screens/BuyAndSellCardano";
import BuyAndSellSolana from "./screens/BuyAndSellSolana";
import ConvertCrypto from "./screens/ConvertCrypto";
import CryptoConvertList from "./screens/CryptoConvertList";

function App() {

  const [checkSignature, setCheckSignature] = useState(false);

  useEffect(() => {
    var signature = window.location.href.split('sig=')
    signature = signature[1]?.replace('_', '+');
    signature = signature?.replace('-', '/');
    if (!signature) {
      switchExchange(localStorage.getItem("signature"));
    }
    else {
      switchExchange(signature);
    }
  }, []);

  const switchExchange = async (signature) => {
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      autoConnect: false,
      transports: ['websocket']
    });
    try {
      const formData = {
        signature: signature,
        region: process.env.REACT_APP_INTERNATIONAL_MARKETS,
      }
      const resultSwitch = await requestHandler('switchExchange', 'post', formData);
      if (resultSwitch && resultSwitch.status === 200) {
        if (resultSwitch.data.data[0].signature) {
          localStorage.setItem("signature", resultSwitch.data.data[0].signature);
          localStorage.setItem("jwt_token", resultSwitch.data.data[0].jwt_token);
          localStorage.setItem("switch_exchange", true);
          document.cookie = "refresh_token=" + resultSwitch.data.data[0].jwt_refresh_token;
          socket.emit('login', { userId: resultSwitch.data.data[0].id });
          setCheckSignature(!checkSignature);
        } else {
          window.location.href = resultSwitch.data.data[0].url;
        };
      };
    } catch (error) {
      window.location.href = process.env.REACT_APP_GLOBAL_URL;
    };
  };

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Page>
              <Home checkSignature={checkSignature} />
            </Page>
          }
        />
        <Route
          exact
          path="/bank-verification"
          element={
            <ProtectedRoutes>
              <Page>
                <BankVerification />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/fiat-deposit-list"
          element={
            <ProtectedRoutes>
              <Page headerWide footerHide>
                <FiatDepositList />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/wallet-transfer-list"
          element={
            <ProtectedRoutes>
              <Page headerWide footerHide>
                <WalletTransferList />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/deposit-fiat"
          element={
            <ProtectedRoutes>
              <Page>
                <DepositFiat />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/profile-info"
          element={
            <ProtectedRoutes>
              <Page>
                <ProfileInfo />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/my-referral"
          element={
            <ProtectedRoutes>
              <Page>
                <MyReferrals />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/coupon-and-rewards"
          element={
            <ProtectedRoutes>
              <Page>
                <CouponAndRewards />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/wallet-overview"
          element={
            <ProtectedRoutes>
              <Page headerWide footerHide>
                <WalletOverview />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/wallet-overview/:id"
          element={
            <ProtectedRoutes>
              <Page headerWide footerHide>
                <WalletOverviewDetails />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/deposit-list"
          element={
            <ProtectedRoutes>
              <Page headerWide footerHide>
                <DepositList />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/withdraw-list"
          element={
            <ProtectedRoutes>
              <Page headerWide footerHide>
                <WithdrawList />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/cashback-and-reward-list"
          element={
            <ProtectedRoutes>
              <Page headerWide footerHide>
                <CashbackAndRewardList />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/wallet/success"
          element={
            <ProtectedRoutes>
              <Page headerWide footerHide>
                <FiatDepositSuccess />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/wallet/failed"
          element={
            <ProtectedRoutes>
              <Page headerWide footerHide>
                <FiatDepositFailed />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/wallet/cancel"
          element={
            <ProtectedRoutes>
              <Page headerWide footerHide>
                <FiatDepositCancel />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/exchange/:slug"
          element={
            <ProtectedRoutes>
              <Page headerWide footerHide>
                <Exchange />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/user-kyc-verification"
          element={
            <ProtectedRoutes>
              <Page>
                <UserKycVerification />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/change-password"
          element={
            <ProtectedRoutes>
              <Page>
                <ChangePassword />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/buy-crypto"
          element={
            <ProtectedRoutes>
              <Page>
                <BuyCrypto />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/sell-crypto"
          element={
            <ProtectedRoutes>
              <Page>
                <SellCrypto />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/orders-history"
          element={
            <ProtectedRoutes>
              <Page headerWide footerHide>
                <OrdersHistory />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/open-orders-list"
          element={
            <ProtectedRoutes>
              <Page headerWide footerHide>
                <OpenOrdersList />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/bitdenex-pay-transactions"
          element={
            <ProtectedRoutes>
              <Page headerWide footerHide>
                <BitdenexPayTransactionList />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/bitdenex-pay"
          element={
            <ProtectedRoutes>
              <Page>
                <BitdenexPay />
              </Page>
            </ProtectedRoutes>
          }
        />
        {/* <Route
          exact
          path="/coin-transfer-details"
          element={
            <ProtectedRoutes>
              <Page headerWide footerHide>
                <RegionTransferList />
              </Page>
            </ProtectedRoutes>
          }
        /> */}
        <Route
          exact
          path="/market"
          element={
            <ProtectedRoutes>
              <Page>
                <Market />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/learn-crypto"
          element={
            <ProtectedRoutes>
              <Page>
                <LearnCrypto />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/video-tutorials"
          element={
            <ProtectedRoutes>
              <Page>
                <Videos />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/learn-crypto-details/:slug"
          element={
            <ProtectedRoutes>
              <Page>
                <LearnCryptoDetails />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/terms-and-conditions"
          element={
            <ProtectedRoutes>
              <Page>
                <TermsAndConditions />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/referral-and-affiliate"
          element={
            <ProtectedRoutes>
              <Page>
                <ReferralProgram />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/coin-listing"
          element={
            <ProtectedRoutes>
              <Page>
                <CoinListing />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/terms-and-conditions-referral-program"
          element={
            <ProtectedRoutes>
              <Page>
                <TermsAndConditionsForReferralProgram />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/privacy-policy"
          element={
            <ProtectedRoutes>
              <Page>
                <PrivacyPolicy />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/kyc-policy"
          element={
            <ProtectedRoutes>
              <Page>
                <KycPolicy />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/aml-policy"
          element={
            <ProtectedRoutes>
              <Page>
                <AmlPolicy />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/location-restriction"
          element={
            <ProtectedRoutes>
              <Page>
                <LocationRestriction />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/terms-and-conditions-for-referral-program"
          element={
            <ProtectedRoutes>
              <Page>
                <TermsAndConditionsForReferralProgram />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/buy-bitcoin"
          element={
            <ProtectedRoutes>
              <Page>
                <BuyAndSellBitcoin />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/buy-tron"
          element={
            <ProtectedRoutes>
              <Page>
                <BuyAndSellTron />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/buy-ethereum"
          element={
            <ProtectedRoutes>
              <Page>
                <BuyAndSellEthereum />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/buy-litecoin"
          element={
            <ProtectedRoutes>
              <Page>
                <BuyAndSellLitecoin />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/buy-ripple"
          element={
            <ProtectedRoutes>
              <Page>
                <BuyAndSellRipple />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/buy-steller"
          element={
            <ProtectedRoutes>
              <Page>
                <BuyAndSellSteller />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/fees"
          element={
            <ProtectedRoutes>
              <Page>
                <Fees />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/help-center"
          element={
            <ProtectedRoutes>
              <Page>
                <HelpCenter />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/legal-center"
          element={
            <ProtectedRoutes>
              <Page>
                <LegalCenter />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/bitdenex-pay"
          element={
            <ProtectedRoutes>
              <Page>
                <BitdenexPay />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/bitdenex-partners"
          element={
            <ProtectedRoutes>
              <Page>
                <BitdenexPartners />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/learn-crypto"
          element={
            <ProtectedRoutes>
              <Page>
                <LearnCrypto />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/bitdenex-app"
          element={
            <ProtectedRoutes>
              <Page>
                <BitdenexApp />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/bitdenex-token"
          element={
            <ProtectedRoutes>
              <Page>
                <BitdenexToken />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/buy-bitdenex-token"
          element={
            <ProtectedRoutes>
              <Page>
                <BuyAndSellBitdenex />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/buy-cardano"
          element={
            <ProtectedRoutes>
              <Page>
                <BuyAndSellCardano />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/buy-solana"
          element={
            <ProtectedRoutes>
              <Page>
                <BuyAndSellSolana />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/staking"
          element={
            <ProtectedRoutes>
              <Page>
                <Staking />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/convert"
          element={
            <ProtectedRoutes>
              <Page headerWide footerHide>
                <ConvertCrypto />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/convert-crypto-list"
          element={
            <ProtectedRoutes>
              <Page headerWide footerHide>
                <CryptoConvertList />
              </Page>
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="*"
          element={
            <PageNotFound />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
